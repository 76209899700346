<template lang="pug">
div(class='SliderPractice')
	UiCarousel(
		class='SliderPractice--Carousel',
		:perView='greaterThan("mobileWide") ? 3 : greaterThan("mobileMedium") ? 2.4 : 1.2',
		:gap='greaterThan("desktop") ? "xl" : greaterThan("tablet") ? "lg" : "md"',
		ref='slider'
	)
		template(#default='{ carouselItemProps }')
			TransitionGroup(name='slider')
				UiCarouselItem(v-for='practice in practice', :key='practice.id', v-bind='carouselItemProps')
					div(class='SliderPractice--Carousel--Item')
						div(class='Practice')
							div(class='Case') Дело № {{ practice.number }}
							div(class='Name') {{ practice.title }}
							UiProse(class='Text', :html='practice.description', awesome)
							UiButton(
								v-if='practice.link',
								:href='practice.link',
								target='_blank',
								variant='secondary',
								iconRight='system/arrow-top-right',
								iconRightSize='sm',
								rounded
							) Дело на сайте суда

	div(v-if='navigation', class='SliderPractice--Navigation')
		UiButton(
			class='SliderPractice--Navigation--Button',
			:class='{ Disabled: activeSlide === 1 }',
			variant='secondary',
			rounded,
			square,
			paddet,
			size='xl',
			iconLeft='system/arrow-left',
			iconLeftSize='md',
			@click='slidePrev'
		)
		div(class='SliderPractice--Navigation--Count')
			span {{ activeSlide }} / {{ greaterThan('mobileWide') ? practice.length - 2 : greaterThan('mobileMedium') ? practice.length - 1 : practice.length }}
		UiButton(
			class='SliderPractice--Navigation--Button',
			:class='{ Disabled: isEndSlide }',
			variant='secondary',
			rounded,
			square,
			size='xl',
			iconRight='system/arrow-right',
			iconRightSize='md',
			@click='slideNext'
		)
</template>

<script lang="ts" setup>
import { Practice } from '~/models/practice';
import { UiCarousel } from '#components';

/**
 * Define refs
 */
const slider = ref<InstanceType<typeof UiCarousel> | null>(null);
const activeSlide = computed(() => slider.value?.activeSlide || 1);
const isEndSlide = computed(() => slider.value?.isEndSlide || false);
const slideNext = () => slider.value?.slideNext();
const slidePrev = () => slider.value?.slidePrev();

/**
 * Define props
 */
const { practice = [], navigation = false } = defineProps<{
	practice: Practice.Model[];
	navigation?: boolean;
}>();

/**
 * Define hooks
 */
const { greaterThan } = usePlatform();
</script>

<style lang="less" scoped>
.SliderPractice {
	.box(grid; 100%; auto; none; 1rem; auto; 100%; center; center);

	&--Carousel {
		.grid(1 span; 1 span);
		.box(block; 100%; auto; none);

		&--Item {
			.box(grid; 100%; 100%; none; 0; 1fr; 1fr; stretch; start);
			& > .Practice {
				padding: 1rem;
				.box(grid; 100%; auto; fade(@ColorBase, 5%); 1rem; auto auto 1fr auto; 1fr; center; start);
				.border-radius(@BorderRadiusSmall);
				& > .Case {
					.grid(1 span);
					.text(@ColorBase; 1.4em 0.875rem @regular; left; none none);
				}
				& > .Name {
					.grid(1 span);
					.text(@ColorBase; 1.4em 1.125rem @bold; left; none none);
				}
				& > .Text {
					.grid(1 span);
					.text(@ColorBase; 1.4em 0.875rem @regular; left; none none);
				}
				& > .UiButton {
					.grid(1 span);
				}

				@media all and (min-width: @laptop) and (max-width: (@desktop - 1px)) {
					padding: 2rem;
					& > .Name {
						.grid(1 span);
						.text(@ColorBase; 1.4em 1.25rem @bold; left; none none);
					}
					& > .Text {
						.grid(1 span);
						.text(@ColorBase; 1.4em 1rem @regular; left; none none);
					}
				}

				@media all and (min-width: @desktop) {
					padding: 2.5rem;
					& > .Name {
						.grid(1 span);
						.text(@ColorBase; 1.4em 1.5rem @bold; left; none none);
					}
					& > .Text {
						.grid(1 span);
						.text(@ColorBase; 1.4em 1.125rem @regular; left; none none);
					}
				}
			}
		}
	}

	&--Navigation {
		margin: 1rem 0 0 0;
		.grid(1 span; 1 span; center; center);
		.box(grid; auto; auto; none; 2rem; 1fr; auto; center; center; column);

		&--Count {
			.grid(1 span);
			.text(@ColorBase; 1em 1.125rem @medium);
		}

		&--Button {
			padding: 1rem !important;
			.grid(1 span);

			&.Disabled {
				border-color: transparent;
				background: fade(@ColorBase, 5%);
				.cursor(default);
				.box-shadow(0; 0; 0; 0; transparent) !important;
			}
		}
	}
}
</style>
