import { practiceService } from '~/services/practice';
import { Practice } from '~/models/practice';

export const usePracticeState = (showOnAbout: boolean = false) => ({
	practiceState: useState<Practice.Model[]>(
		showOnAbout ? `practiceState` : 'aboutPracticeState',
		() => []
	),
	practiceLoaded: useState<boolean>(
		showOnAbout ? 'practiceLoaded' : 'aboutPracticeLoaded',
		() => false
	),
	practicePending: useState<boolean>(
		showOnAbout ? 'practicePending' : 'aboutPracticePending',
		() => true
	),
});

export const usePractice = (showOnAbout: boolean = false) => {
	const { practiceState, practiceLoaded, practicePending } = usePracticeState(showOnAbout);

	/**
	 * Fetch practice
	 */
	const fetchPractice = async () => {
		if (practiceLoaded.value) return;

		practicePending.value = true;

		practiceState.value = await practiceService.fetch(showOnAbout);

		practiceLoaded.value = true;
		practicePending.value = false;
	};

	/**
	 * Refresh practice
	 */
	const refreshPractice = async () => {
		practiceLoaded.value = false;

		await fetchPractice();
	};

	return {
		practiceState,
		practicePending,

		fetchPractice,
		refreshPractice,
	};
};
