import { Common } from '~/models/common';
import { Practice } from '~/models/practice';
import { Image } from '~/models/image';

const collection = 'practice';

export const practiceService = {
	fetch: async (showOnAbout: boolean = false): Promise<Practice.Model[]> => {
		const { getItems } = useDirectusItems();

		const response = await getItems<
			Omit<Practice.Model, 'images'> & { images: { directus_files_id: Image.Model }[] }
		>({
			collection,
			params: {
				filter: {
					status: Common.Status.PUBLISHED,
					...(showOnAbout ? { showOnAbout: true } : {}),
				},
				fields: ['*', 'images.*', 'images.directus_files_id.*', 'form.*', 'seo.*'],
			},
		});

		/** Resolve images */
		const resolvedImagesResponse = response.reduce((acc, practice) => {
			const images = practice.images.reduce((acc, { directus_files_id }) => {
				acc.push(directus_files_id);
				return acc;
			}, [] as Image.Model[]);

			acc.push({ ...practice, images });

			return acc;
		}, [] as Practice.Model[]);

		return resolvedImagesResponse;
	},
};
